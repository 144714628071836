var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('headerText'),_vm._m(0),_c('div',{staticClass:"curriculumBox"},[_vm._m(1),_c('div',{staticClass:"coure_left"},[_vm._m(2),_c('div',{staticClass:"lore"},[_c('div',{staticClass:"video_one"},[_c('div',{staticClass:"video_bg"},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isShow),expression:"!isShow"}],staticClass:"video_img",on:{"click":_vm.playVideo}},[_c('span',{staticClass:"video_play"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShow),expression:"isShow"}],staticClass:"video_open"},[_c('video',{ref:"videoPlayer",attrs:{"preload":"","disablepictureinpicture":"","controlslist":"nodownload","controls":"","width":"550","height":"300","src":"http://file.zjxk12.com/mp4/1568712089502.mp4"}})])])])])])]),_vm._m(3),_vm._m(4),_vm._m(5),_c('footerText')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"midBox"},[_c('div',{staticClass:"present"},[_c('div',{staticClass:"graphic"},[_c('span',{staticClass:"course fb"},[_vm._v(" 让课程学习 ")]),_c('span',{staticClass:"interesting fb"},[_vm._v(" 有趣味 变美好 ")])]),_c('div',{staticClass:"study fs"},[_vm._v(" 学生应景学、趣味学、自主学 ")]),_c('div',{staticStyle:{"margin-top":"20px","padding-left":"10%"}},[_c('div',{staticClass:"photo"},[_c('img',{staticClass:"boy",attrs:{"src":require("../../assets/img/img_boy.png"),"alt":""}}),_c('img',{staticClass:"girl",attrs:{"src":require("../../assets/img/img_girl.png"),"alt":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"curriculum_header"},[_c('p',{staticClass:"primary fb"},[_vm._v("小学最美课本")]),_c('span',{staticClass:"passive fb"},[_vm._v(" 让学生从被动学习变为 ")]),_c('span',{staticClass:"initiative fb"},[_vm._v(" 主动学习 ")]),_c('p',{staticClass:"bewrite fs"},[_vm._v("同步教科书 双语美育 标准普通话和美式英语 多种艺术表现手法 课文内容生动有趣")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"sort"},[_c('li',{staticClass:"list"},[_c('div',{staticClass:"mathing"},[_c('img',{staticClass:"diamond",attrs:{"src":require("../../assets/img/img_blueround.png"),"alt":""}}),_c('span',{staticClass:"topic fs"},[_vm._v("课程优势")])]),_c('p',{staticClass:"grade fss"},[_vm._v(" 同步教材、双语配音、应景学习、美育 ")])]),_c('li',{staticClass:"list"},[_c('div',{staticClass:"mathing"},[_c('img',{staticClass:"diamond",attrs:{"src":require("../../assets/img/img_blueround.png"),"alt":""}}),_c('span',{staticClass:"topic fs"},[_vm._v(" 学科分类 ")])]),_c('p',{staticClass:"grade fss"},[_vm._v("语文 数学 英语")])]),_c('li',{staticClass:"list"},[_c('div',{staticClass:"mathing"},[_c('img',{staticClass:"diamond",attrs:{"src":require("../../assets/img/img_blueround.png"),"alt":""}}),_c('span',{staticClass:"topic fb"},[_vm._v("年级课程")])]),_c('p',{staticClass:"grade fss"},[_vm._v("小学1-6年级")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"knowBox"},[_c('div',{staticClass:"know_content"},[_c('div',{staticClass:"publicize"},[_c('span',{staticClass:"experience fb"},[_vm._v(" 让孩子体会到：原来学习是一件很 ")]),_c('span',{staticClass:"fine fb"},[_vm._v(" 美好 ")]),_c('span',{staticClass:"thing fb"},[_vm._v(" 的事情 ")]),_c('p',{staticClass:"textbook fs"},[_vm._v("“最美课本” 让学生更有粘性")])]),_c('ul',{staticClass:"oul"},[_c('li',[_c('div',{staticClass:"title_top"},[_c('img',{staticClass:"orange",attrs:{"src":require("../../assets/img/img_orange.png"),"alt":""}})]),_c('div',{staticClass:"title_bottom"},[_c('p',{staticClass:"nice fb"},[_vm._v("体验美")]),_c('p',{staticClass:"wire"}),_c('span',{staticClass:"explain fs"},[_vm._v(" 学生通过听觉、 视觉、 思考 “全感官”学习 ")])])]),_c('li',{staticStyle:{"margin":"0px 48px"}},[_c('div',{staticClass:"title_content"},[_c('img',{staticClass:"orange",attrs:{"src":require("../../assets/img/img_yellow.png"),"alt":""}})]),_c('div',{staticClass:"title_bottom"},[_c('p',{staticClass:"nice fb"},[_vm._v("创造美")]),_c('p',{staticClass:"wire"}),_c('span',{staticClass:"explain fs"},[_vm._v(" 学生可根据课程绘画、创作、 朗读、配音 ")])])]),_c('li',[_c('div',{staticClass:"title_right"},[_c('img',{staticClass:"orange",attrs:{"src":require("../../assets/img/img_blue.png"),"alt":""}})]),_c('div',{staticClass:"title_bottom"},[_c('p',{staticClass:"nice fb"},[_vm._v("分享美")]),_c('p',{staticClass:"wire"}),_c('span',{staticClass:"result fs"},[_vm._v(" 学生将学习成果 可以分享 给家长、 同学、老师，或者 朋友圈 ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cultureBox"},[_c('div',{staticClass:"culture_content"},[_c('span',{staticClass:"thought fb"},[_vm._v(" 全面培养学生的 ")]),_c('span',{staticClass:"creativity fb"},[_vm._v(" 想象力 ")]),_c('span',{staticClass:"thought fb"},[_vm._v(" 个性化 ")]),_c('span',{staticClass:"creativity fb"},[_vm._v(" 思维能力 ")]),_c('span',{staticClass:"thought fb"},[_vm._v(" 和 ")]),_c('span',{staticClass:"creativity fb"},[_vm._v(" 创造力 ")]),_c('img',{staticClass:"learnimg",attrs:{"src":require("../../assets/img/img_learn.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"down_pageBox"},[_c('div',{staticClass:"down_info"},[_c('img',{staticClass:"img_iphone",attrs:{"src":require("../../assets/img/_s-img_learn.png"),"alt":""}}),_c('div',{staticClass:"app_info"},[_c('p',{staticClass:"app_title fb"},[_vm._v("再佳学《最美课本》APP")]),_c('p',{staticClass:"client fs"},[_vm._v("专属手机客户端")]),_c('div',{staticClass:"clearfix"},[_c('img',{staticClass:"img_code fr",attrs:{"src":require("../../assets/img/erweima.png"),"alt":""}}),_c('div',{staticClass:"ipone_down"},[_c('div',{staticClass:"ios_info"},[_c('img',{staticClass:"ios",attrs:{"src":require("../../assets/img/icon_ios.png"),"alt":""}}),_c('span',{staticClass:"ios_title fs"},[_vm._v("iOS苹果端下载")])])]),_c('div',{staticClass:"android_down"},[_c('div',{staticClass:"and_info"},[_c('img',{staticClass:"android",attrs:{"src":require("../../assets/img/icon_andriod.png"),"alt":""}}),_c('span',{staticClass:"android_title fs"},[_vm._v("Android安卓端下载")])])])])])])])
}]

export { render, staticRenderFns }