<template>
    <div class="content">
        <headerText></headerText>
        <!-- 中部 -->
        <div class="midBox">
            <div class="present">
                <div class="graphic">
                    <span class="course fb">
                        让课程学习
                    </span>
                    <span class="interesting fb">
                        有趣味 变美好
                    </span>
                </div>
                <div class="study fs">
                    学生应景学、趣味学、自主学
                </div>
                <div style="margin-top: 20px;padding-left: 10%">
                    <div class="photo">
                        <img class="boy" src="../../assets/img/img_boy.png" alt="">
                        <img class="girl" src="../../assets/img/img_girl.png" alt="">
                    </div>
                </div>
            </div>
        </div>
        <!-- 第三部分 -->
        <div class="curriculumBox">
            <div class="curriculum_header">
                <p class="primary fb">小学最美课本</p>
                <span class="passive fb">
                    让学生从被动学习变为
                </span><span class="initiative fb">
                    主动学习
                </span>
                <p class="bewrite fs">同步教科书 双语美育 标准普通话和美式英语 多种艺术表现手法 课文内容生动有趣</p>
            </div>
            <div class="coure_left">
                <ul class="sort">
                    <li class="list">
                        <div class="mathing">
                            <img class="diamond" src="../../assets/img/img_blueround.png" alt="">
                            <span class="topic fs">课程优势</span>
                        </div>
                        <p class="grade fss">
                            同步教材、双语配音、应景学习、美育
                        </p>
                    </li>
                    <li class="list">
                        <div class="mathing">
                            <img class="diamond" src="../../assets/img/img_blueround.png" alt="">
                            <span class="topic fs">
                                学科分类
                            </span>
                        </div>
                        <p class="grade fss">语文 数学 英语</p>
                    </li>
                    <li class="list">
                        <div class="mathing">
                            <img class="diamond" src="../../assets/img/img_blueround.png" alt="">
                            <span class="topic fb">年级课程</span>
                        </div>
                        <p class="grade fss">小学1-6年级</p>
                    </li>
                </ul>
                <div class="lore">
                    <div class="video_one">
                        <div class="video_bg">
                            <p class="video_img" @click="playVideo" v-show="!isShow">
                                <span class="video_play"></span>
                            </p>
                            <div class="video_open" v-show="isShow">
                                <video
                                    ref="videoPlayer" 
                                    preload
                                    disablepictureinpicture
                                    controlslist="nodownload"
                                    controls 
                                    width="550" height="300"
                                    src="http://file.zjxk12.com/mp4/1568712089502.mp4">
                                </video>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 第四部分 -->
        <div class="knowBox">
            <div class="know_content">
                <div class="publicize">
                    <span class="experience fb">
                        让孩子体会到：原来学习是一件很
                    </span>
                    <span class="fine fb">
                        美好
                    </span>
                    <span class="thing fb">
                        的事情
                    </span>
                    <p class="textbook fs">“最美课本” 让学生更有粘性</p>
                </div>
                <ul class="oul">
                    <li>
                        <div class="title_top">
                            <img class="orange" src="../../assets/img/img_orange.png" alt="">
                        </div>
                        <div class="title_bottom">
                            <p class="nice fb">体验美</p>
                            <p class="wire"></p>
                            <span class="explain fs">
                                学生通过听觉、 视觉、 思考
                                “全感官”学习
                            </span>
                        </div>
                    </li>
                    <li style="margin: 0px 48px">
                        <div class="title_content">
                            <img class="orange" src="../../assets/img/img_yellow.png" alt="">
                        </div>
                        <div class="title_bottom">
                            <p class="nice fb">创造美</p>
                            <p class="wire"></p>
                            <span class="explain fs">
                                学生可根据课程绘画、创作、
                                朗读、配音
                            </span>
                        </div>
                    </li>
                    <li>
                        <div class="title_right">
                            <img class="orange" src="../../assets/img/img_blue.png" alt="">
                        </div>
                        <div class="title_bottom">
                            <p class="nice fb">分享美</p>
                            <p class="wire"></p>
                            <span class="result fs">
                                学生将学习成果 可以分享
                                给家长、 同学、老师，或者 朋友圈
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="cultureBox">
            <div class="culture_content">
                <span class="thought fb">
                    全面培养学生的
                </span>
                <span class="creativity fb">
                    想象力
                </span>
                <span class="thought fb">
                    个性化
                </span>
                <span class="creativity fb">
                    思维能力
                </span>
                <span class="thought fb">
                    和
                </span>
                <span class="creativity fb">
                    创造力
                </span>
                <!-- 缺少图片 -->
                <!-- ../../assets/img/_s-img_learn.png -->
                <img class="learnimg" src="../../assets/img/img_learn.png" alt="">
            </div>
        </div>
        <!-- 第五部分 -->
        <div class="down_pageBox">
            <div class="down_info">
                <img class="img_iphone" src="../../assets/img/_s-img_learn.png" alt="">
                <div class="app_info">
                    <p class="app_title fb">再佳学《最美课本》APP</p>
                    <p class="client fs">专属手机客户端</p>
                    <div class="clearfix">
                        <img class="img_code fr" src="../../assets/img/erweima.png" alt="">
                        <div class="ipone_down">
                            <div class="ios_info">
                                <img class="ios" src="../../assets/img/icon_ios.png" alt="">
                                <span class="ios_title fs">iOS苹果端下载</span>
                            </div>
                        </div>
                        <div class="android_down">
                            <div class="and_info">
                                <img class="android" src="../../assets/img/icon_andriod.png" alt="">
                                <span class="android_title fs">Android安卓端下载</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 底部 -->
        <footerText></footerText>
    </div>
</template>

<script>
    
    export default {
        data() {
            return {
                isShow: false
            };
        },
        mounted() {
            if (/Android|iPhone|iPod/i.test(navigator.userAgent)) {
                this.$router.push('/indexEn')
            }
        },
        methods: {
            playVideo(){
                this.isShow = true;
                this.$refs.videoPlayer.play();
            },
        }
    };
</script>

<style scoped>
    @import '../../assets/css/index.css';
</style>
